<template>
  <el-dropdown class="icon-top" trigger="click" @command="AcaoAjuda">
    <span class="el-dropdown-link">
      <icon name="question" scale="1"></icon>
    </span>
    <el-dropdown-menu slot="dropdown" ref="dropdownMenu">
      <el-dropdown-item command="verTutoriais">
        <i class="el-icon-video-play"></i> Central de atendimento
      </el-dropdown-item>
      <el-dropdown-item command="abrirChamado">
        <i class="el-icon-edit-outline"></i> Abrir chamado
      </el-dropdown-item>
      <el-dropdown-item command="agendarCall">
        <i class="el-icon-date"></i> Call com o Comercial
      </el-dropdown-item>
      <el-dropdown-item command="statusPlataforma">
        <i class="el-icon-circle-check"></i> Status da plataforma
      </el-dropdown-item>
      <el-dropdown-item command="pesquisaSatisfacao">
        <i class="el-icon-star-off"></i> Pesquisa de satisfação
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  name: "BotaoAjuda",
  mounted() {
    // Adicionar listener ao dropdown menu após montagem do componente
    this.$refs.dropdownMenu.$on('command', this.AcaoAjuda);
  },
  methods: {
    AcaoAjuda(option) {
      if (option === "verTutoriais") {
        window.open("https://atendimento.moblix.com.br/", "_blank");
      } else if (option === "abrirChamado") {
        window.open("https://moblix.freshdesk.com/support/tickets/new", "_blank");
      } else if (option === "agendarCall") {
        this.inicializarCalendly();
      } else if (option === "pesquisaSatisfacao") {
        this.$router.push("/Feedback");
      } else {
        window.open("https://stats.uptimerobot.com/BgLgnsxenG", "_blank");
      }
    },
    inicializarCalendly() {
      // Carregar CSS do Calendly
      if (!document.getElementById('calendly-css')) {
        var link = document.createElement('link');
        link.id = 'calendly-css';
        link.href = 'https://assets.calendly.com/assets/external/widget.css';
        link.rel = 'stylesheet';
        document.head.appendChild(link);
      }

      // Carregar script do Calendly
      if (window.Calendly) {
        window.Calendly.initPopupWidget({ url: 'https://calendly.com/moblix/30min' });
      } else {
        var calendlyScript = document.createElement("script");
        calendlyScript.src = "https://assets.calendly.com/assets/external/widget.js";
        calendlyScript.onload = () => {
          window.Calendly.initPopupWidget({ url: 'https://calendly.com/moblix/30min' });
        };
        document.body.appendChild(calendlyScript);
      }
    }
  }
}
</script>

<style scoped></style>
